.profile_management_container {
  display: flex;
  flex-direction: column;
  background: #e1e8f0;
  min-height: 100vh;
}

.gradient {
  height: 200px;
  width: 100%;
  background-image: -webkit-linear-gradient(0,
      rgb(63, 167, 240) 0%,
      rgb(240, 30, 90) 100%);
  box-shadow: 0px 1px 10px 0px;
}

.image_row {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 70px;
}

.profile_image_container {
  width: 200px;
  height: 200px;
}

.profile_image {
  object-fit: cover;
  overflow: hidden;
  image-orientation: from-image;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.name {
  margin: 0;
  font-weight: 200;
}

.email {
  margin: 0;
  font-weight: 300;
  margin-bottom: 10px;
}

@media (max-width: 829px) {
  .row {
    justify-content: center;
  }

  .description {
    text-align: center;
  }

  .file_upload {
    padding: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 830px) {
  .row {
    justify-content: space-between;
  }

  .description {
    padding-left: 20px;
    text-align: left;
  }

  .file_upload {
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
}

.row {
  width: 70%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.description {
  line-height: 1rem;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
}

a:visited {
  color: #0000ee;
}

.chamberman_input {
  margin: 0 20px !important;
}

.download_button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 15px;
  color: #0000ee;
}

.info_row {
  text-align: center;
}

.image_upload {
  width: 200px;
  height: 200px;
  text-align: center;
  top: 40px;
  position: absolute;
  background: #e1e8f0;
  border-radius: 100px;
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.5);
}

.profile_upload {
  display: none;
}

.profile_loading {
  width: 200px !important;
  height: 200px !important;
}

.save_container {
  width: 72%;
  align-self: center;
  padding-bottom: 50px;
  align-content: center;
}

.save_button {
  height: 55px;
}

.page_loading_indicator {
  position: fixed;
  left: 47.5%;
  top: 50%;
}

.row_privacy_policy {
  width: 70%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: left;
}

.footer_disclaimer {
  margin: 5px 20px !important;
  width: 100%;
  align-items: center;
  font-size: 12px;
  color: darkgrey;
}

.address_form_input {
  margin: 5px 20px !important;
  width: 100%;
}
